import React from "react";
import { Button } from "react-bootstrap";
import Svg from "../svg";


function About(props) {

  return (
    <>
      <div className="about-wrapper">
        <div className="about-wrapper-title">About GradRight</div>
        <div className="about-container">
          <div className="about-container-left">
            <div className="left-title">
              WORLD'S FIRST EDUCATION LOAN BIDDING PLATFORM
            </div>
            <div className="left-desc">
              GradRight is an ed-fin-tech startup focused on global higher
              education. We are committed to enabling global youth aspirations
              by helping students find the "right education" at the "right
              cost." Since September 2020, we have received over US$500 million
              in loan requests and successfully secured over US$75 million in
              loan approvals for more than 1600 students.
            </div>
          </div>
          <div className="about-cotainer-right">
              <Svg svgIcon="about-svg" width="412" height="472" viewBox="0 0 412 472" />
          </div>
        </div>
      </div>
      <div className="sign-up-btn-div">
        <a rel="noreferrer" href={`https://fund.gradright.com/signup${props?.location?.search}`} target="_blank">
          <Button size="lg" className="sign-up-btn" variant="primary">
            Sign Up Now
          </Button>
        </a>
      </div>
    </>
  );
}

export default About;
