import * as React from "react";
import { Helmet } from "react-helmet";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../Styles/globalStyle.scss";
import Header from "./Header/header";
import Footer from "./Footer/footer";
import Offers from "./OfferCard/offers";
import About from "./About/about";
import HowItWorks from "./HowItWorks/howItWorks";

const IndexPage = (props) => {

  return (
    <>
      <Helmet>
        <title>
          {props.pageContext?.universityShortName} - GradRight Group Offer
        </title>
        <meta charset={"utf-8"} />
        <meta
          name={"description"}
          content="The hassle-free platform to find the 'best loan' for your Master's abroad."
        />
        <meta name={"format-detection"} content="telephone=no" />
        <meta name={"msapplication-tap-highlight"} content={"no"} />
        <meta
          name={"viewport"}
          content="user-scalable=no,initial-scale=1,maximum-scale=1,minimum-scale=1,width=device-width"
        />
        <meta
          property="og:title"
          content={`${props.pageContext?.universityShortName} - GradRight Group Offer`}
        />
        <meta
          property="og:description"
          content="The hassle-free platform to find the 'best loan' for your Master's abroad."
        />
        <meta
          property="og:url"
          content={`https://groupoffers.gradright.com/${props.path}`}
        />
        <meta property="og:image" content="og-logo.png" />
      </Helmet>
      <div className="offer-container">
        <Header data={props.pageContext} />
        <Offers data={props.pageContext} />
      </div>
      <HowItWorks props={props.pageContext} {...props} />
      <About
        data={props.pageContext}
        {...props}
        style={{ marginTop: "60px" }}
      />
      <Footer data={props.pageContext} />
    </>
  );
};

export default IndexPage;
