import * as React from "react";
import Svg from "../svg";

function Header(props) {
  const {data} = props;

  return (
    <>
      <div className="header">
        <Svg
          height="49"
          width="301"
          viewBox="0 0 301 49"
          svgIcon="company-logo"
          className="header-company-logo"
        />
        <img alt="company_logo" className="m-l-10" src={data?.universityLogoImage} />
      </div>
      <div className="location">
        <Svg
          width="22"
          height="26"
          viewBox="0 0 22 26"
          svgIcon="location"
          className="m-r-10"
        />
        <div className="m-l-10">
          {data?.universityAddress}
        </div>
      </div>
      <div className="website">
        <Svg
          width="22"
          height="22"
          viewBox="0 0 22 22"
          svgIcon="website"
          className="m-r-10"
        />
        <div className="m-l-10">
          <a rel="noreferrer" target="_blank" href={data?.universityWebsite} > {data?.universityWebsite}</a>
        </div>
      </div>
      <div className="header-title">GradRight Group Offer for {data?.universityShortName} admitted students</div>
      <div className="header-desc">
      GradRight invites all the admitted students of {data?.universityLongName}, enrolling in 2022 to participate in this exclusive group offer that isn't available for any individual student. Signup, join the group and spread the word among your future batchmates!
      </div>
    </>
  );
}

export default Header;
