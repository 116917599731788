import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from 'moment';
import OfferCard from "./offerCard";
import validity from "../../../images/validity.svg";
import CircularProgressBar from "../circularProgress";

function Offers(props) {
  const [details, setDetails] = useState([]);
  const getParticipent = () => {
    axios
      .post('https://fundapi.gradright.com/v1/admin/group-offers/details-for-university',
        { universityCampusIds: [props.data.universityCampusId] }
      )
      .then((resp) => {
        setDetails(resp.data.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    getParticipent();
  },[]);

  return (
    <>
      <div className="offer-section">
        {props?.data?.offers?.map((offer, index) => (
          <OfferCard key={index} data={offer} />
        ))}
      </div>
      <div className="participate-count-wrapper">
        {details.map((data,index) => (
          <div key={index} className="participate-count">
            <div className="participate-count-left">
              <CircularProgressBar percentage={(data.participants * 100) / data.size} label={`${data.participants} / ${data.size}`} />
              <div className="progress-bar-label">Particiating</div>
            </div>
            <div className="participate-count-right">
              <div className="participate-count-header">Share and save!</div>
              <div className="participate-count-description">
                Invite future batchmates, save and help save ₹ {data.savings / 100000} lacs each
              </div>
              <div className="participate-count-footer">
                <img alt="logo" src={validity} /> <span className="m-l-10">Offer valid till {moment(data.deadline).add(-1, 'days').format('MMMM Do YYYY')}</span>
              </div>
            </div>
          </div>
        ))}
      </div>
     </>
  );
}

export default Offers;
