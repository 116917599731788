import React from "react";
import Svg from "../svg";
import student from "../../../images/student.svg";
import saving from "../../../images/saving.svg";
import academics from "../../../images/Academics.svg";
import bank from "../../../images/bank.svg";
import { Button } from "react-bootstrap";

const highLights = [
  {
    header: "Savings per student",
    title: "₹ 10+ lacs",
    image: saving,
  },
  {
    header: "Students serviced",
    title: "1000+",
    image: student,
  },
  {
    header: "Universities funded",
    title: "300+",
    image: academics,
  },
  {
    header: "Lenders on platform",
    title: "14+",
    image: bank,
  },
];

function HowItWorks(props) {

  return (
    <>
      <div className="howItWorks-wrapper">
        <div className="sign-up-btn-div">
          <a
            rel="noreferrer"
            href={`https://fund.gradright.com/signup${props?.location?.search}`}
            target="_blank"
          >
            <Button size="lg" className="sign-up-btn" variant="primary">
              Sign Up Now
            </Button>
          </a>
        </div>
        <div className="about-wrapper-title">How Group Offer Works</div>
        <div className="work-flow-diagram">
          <div className="work-flow-step">
            <Svg
              width="147"
              height="138"
              viewBox="0 0 147 138"
              svgIcon="step1"
            />
            <div className="work-flow-label">Signup on Fundright</div>
          </div>
          <div className="work-flow-step-arrow">
            <Svg
              svgIcon="workflow-arrow"
              width="84"
              height="19"
              viewBox="0 0 84 19"
            />
          </div>
          <div className="work-flow-step">
            <Svg
              svgIcon="step2"
              width="166"
              height="135"
              viewBox="0 0 166 135"
            />
            <div className="work-flow-label">
              Find a group offer that you are eligible for
            </div>
          </div>
          <div className="work-flow-step-arrow">
            <Svg
              svgIcon="workflow-arrow"
              width="84"
              height="19"
              viewBox="0 0 84 19"
            />
          </div>
          <div className="work-flow-step">
            <Svg
              svgIcon="step3"
              width="180"
              height="120"
              viewBox="0 0 180 120"
            />
            <div className="work-flow-label">
              Invite your future batchmates to hit the target number of
              participating students of your group
            </div>
          </div>
          <div className="work-flow-step-arrow">
            <Svg
              svgIcon="workflow-arrow"
              width="84"
              height="19"
              viewBox="0 0 84 19"
            />
          </div>
          <div className="work-flow-step">
            <Svg
              width="174"
              height="122"
              viewBox="0 0 174 122"
              svgIcon="step4"
            />
            <div className="work-flow-label">
              Pay the processing fee by the deadline
            </div>
          </div>
          <div className="work-flow-step-arrow">
            <Svg
              svgIcon="workflow-arrow"
              width="84"
              height="19"
              viewBox="0 0 84 19"
            />
          </div>
          <div className="work-flow-step">
            <Svg
              width="113"
              height="131"
              viewBox="0 0 113 131"
              svgIcon="step5"
            />
            <div className="work-flow-label">
              15 days of the deadline, you will receive an updated sanction
              letter, with the updated offer details
            </div>
          </div>
        </div>
      </div>
      <div className="highlights-wrapper">
        <div className="about-wrapper-title">GradRight Highlights</div>

        <div className="highlight-card-container">
          {highLights.map((data, index) => (
            <div key={index} className="highlight-card">
              <div>
                <img alt="" src={data.image} />
              </div>
              <div className="highlight-card-header">{data.header}</div>
              <div className="highlight-card-title">{data.title}</div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default HowItWorks;
