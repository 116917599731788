import React from "react";

function OfferCard(props) {
  return (
    <div className="offer-card">
      <div className="header">{props?.data?.programName === 'All' ? 'All STEM Master’s Programs' : props?.data?.programName}</div>
      <div className="caption-title">Max. Loan Amount</div>
      <div className="m-b-15 loan-amount">₹ {props?.data?.maxLoanAmount} lacs</div>
      <div className="caption-title">Interest Rate</div>
      <div>
        <span className="caption-title line-through">{props?.data?.interestRate}%</span>
        <span className="intrest-rate">{props?.data?.groupOfferInterestRate}%</span>
      </div>
      {props.children}
    </div>
  );
}

export default OfferCard;
